import maxWidth from './maxWidth';

export default {
    width: '100%',
}

export const sectionStyle = {
    maxWidth: maxWidth,
    margin: '0 auto',
}

export const mainTextStyle = {
    maxWidth: maxWidth * .8,
    margin: '0 auto',
    padding: 25
}

export const headerStyle = {
    textAlign: 'center',
    padding: '50px 0 25px'
}