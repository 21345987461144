import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ContactFooter from "../components/ContactForm/ContactFooter"
import SEO from "../components/seo"
import mainStyle, {
  headerStyle,
  mainTextStyle,
  sectionStyle,
} from "../components/styles/mainStyle"

const CgPluginPage = () => {
  const {
    prismicBasicInfo: {
      data: { orange, main_color_two, main_color_three },
    },
  } = useStaticQuery(graphql`
    query cgPlugin {
      prismicBasicInfo {
        data {
          orange
          main_color_two
          main_color_three
        }
      }
    }
  `)

  return (
    <>
      <SEO
        title="Circulair geld voor WordPress WooCommerce"
        description="Accepteer Circulaire Euro's in uw WordPress WooCommerce webshop met deze plugin."
      />

      <main
        className="borderradius-div-bottom"
        style={{ ...mainStyle, backgroundColor: main_color_two }}
      >
        <section style={sectionStyle}>
          <h1 className="resp-header" style={headerStyle}>
            Circulair Geld betalingsplugin voor WordPress WooCommerce
          </h1>

          <section id="uitgebreide-text" style={mainTextStyle}>
            <p>Maak nu gebruik van deze gratis Wordpress/WooCommerce plugin!</p>

            <h3>Hoe werkt het?</h3>
            <ol>
              <li>
                Zoek in de WordPress plugin directory naar <i>Circulair Geld</i>
              </li>
              <li>Installeer en activeer de plugin in WordPress</li>
              <li>Activeer de plugin in WooCommerce</li>
              <li>Vul uw gebruikersnaam en wachtwoord in</li>
              <li>
                Optioneel maar zeer geadviseerd: Vraag een AccessClient token
                aan
              </li>
              <li>
                U bent nu klaar om betalingen met circulair geld te ontvangen op
                uw website
              </li>
            </ol>

            <p>
              Benieuwd naar de broncode, of wil je daar aan bijdragen?{" "}
              <a
                href="https://github.com/Marco-Daniel/woocommerce-circulair-geld"
                target="_blank"
                rel="noreferrer noopener"
              >
                Klik dan hier!
              </a>
            </p>

            <p
              style={{
                paddingTop: "2.2em",
                textAlign: "center",
                fontSize: "1.2em",
                lineHeight: "1.8em",
              }}
            >
              Heeft u hulp nodig bij het installeren? Ander (WordPress) maatwerk
              nodig?
              <br />
              Neem dan nu snel contact op!
            </p>
          </section>

          <div style={{ textAlign: "center" }}>
            <a
              href="https://nl.wordpress.org/plugins/circulair-geld/"
              target="_blank"
              rel="noopener"
              style={{
                display: "inline-block",
                color: main_color_three,
                textDecoration: "none",
                backgroundColor: orange,
                padding: 15,
                margin: "25px 0 75px",
                fontWeight: "bold",
                borderRadius: "5px 25px 5px 5px",
              }}
            >
              WordPress plugin directory
            </a>
          </div>
        </section>
      </main>

      <ContactFooter />
    </>
  )
}

export default CgPluginPage
